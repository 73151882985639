import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  buttonPosition: {
    bottom: 60,
    left: 'auto',
    right: 0,
    position: 'absolute',
    fontSize: '14px',
    textTransform: 'none',
    borderRadius: 0,
    color: '#FFFFFF',
    backgroundColor: '#343434',
    padding: '8px 30px 8px 23px',
    border: '1px solid #5A5863',
    '&:hover': {
      backgroundColor: '#5A5863'
    },
    [theme.breakpoints.down('xs')]: {
      bottom: 26,
      fontSize: '12px'
    },
    [theme.breakpoints.down('md')]: {
      bottom: 34
    }
  }
}));
const SkipCtaButton = ({ handleClick }) => {
  const classes = useStyles();
  return (
    <Button className={classes.buttonPosition} onClick={handleClick} variant="contained">
      Skip Ad
    </Button>
  );
};

SkipCtaButton.propTypes = {
  handleClick: PropTypes.func.isRequired
};

export default SkipCtaButton;
